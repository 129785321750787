import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from "./shared/shared.module";
import {HttpClientModule} from "@angular/common/http";
import {JwtModule} from "@auth0/angular-jwt";
import {environment} from "../environments/environment";
import {HomeModule} from "./home/home.module";

export function tokenGetter() {
    return localStorage.getItem("tonte-auth-token");
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ['localhost:5000'],
                disallowedRoutes: [environment.apiUrl + 'Auth/'],
            },
        })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
