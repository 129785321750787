<mat-sidenav-container style="height: 100%; background-color: white" >
    <mat-sidenav style="min-width: 200px" #sidenav mode="over"  >
        <div class="sidenav-list" fxLayout="column" fxLayoutAlign="start center" fxFlex fxLayoutGap="10px" >
            <a mat-button (click)="sidenav.close()" routerLink="/" >ホーム</a>
            <a mat-button (click)="sidenav.close()" routerLink="about" routerLinkActive="router-link-active" >お菓子屋山 Tonteについて</a>
            <a mat-button (click)="sidenav.close()" routerLink="order" routerLinkActive="router-link-active" >お菓子のオーダー</a>
            <a mat-button rel="noreferrer" href="https://tonte.thebase.in/" target="_blank" >お菓子便</a>
            <a mat-button (click)="sidenav.close()" routerLink="gallery" routerLinkActive="router-link-active" >山＆お菓子ギャラリー</a>
            <a mat-button (click)="sidenav.close()" routerLink="contact" routerLinkActive="router-link-active" >お問い合わせ</a>
        </div>
    </mat-sidenav>

    <mat-sidenav-content >
        <mat-toolbar style="background-color: whitesmoke; height: 100px; position: fixed">
            <div fxLayout fxFlex>
                <div fxFlex fxHide.gt-sm id="menu-toggle" fxLayout (click)="sidenav.toggle()" fxLayoutGap="20px" >
                    <img src="https://res.cloudinary.com/lido-it/image/upload/v1642989461/tonte/menu_button.ico" >
                    <span fxFlexAlign="center" class="primary-brown font-big japanese-font" >メニュー</span>
                </div>

                <div fxHide.lt-md fxFlex fxLayout fxLayoutAlign="end">
                    <a mat-button (click)="sidenav.close()" routerLink="/" >ホーム</a>
                    <a mat-button (click)="sidenav.close()" routerLink="about" routerLinkActive="router-link-active" >お菓子屋山 Tonteについて</a>
                    <a mat-button (click)="sidenav.close()" routerLink="order" routerLinkActive="router-link-active" >お菓子のオーダー</a>
                    <a mat-button (click)="sidenav.close()" rel="noreferrer" href="https://tonte.thebase.in/" target="_blank" >お菓子便</a>
                    <a mat-button (click)="sidenav.close()" routerLink="gallery" routerLinkActive="router-link-active" >山＆お菓子ギャラリー</a>
                    <a mat-button (click)="sidenav.close()" routerLink="contact" routerLinkActive="router-link-active" >お問い合わせ</a>
                </div>
            </div>

        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
