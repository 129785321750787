import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {path: '', loadChildren: () => import('./home/home.module').then(x => x.HomeModule)},
    {path: 'about', loadChildren: () => import('./about/about.module').then(x => x.AboutModule)},
    {path: 'contact', loadChildren: () => import('./contact/contact.module').then(x => x.ContactModule)},
    {path: 'gallery', loadChildren: () => import('./gallery/gallery.module').then(x => x.GalleryModule)},
    {path: 'order', loadChildren: () => import('./order/order.module').then(x => x.OrderModule)},
    {path: 'login', loadChildren: () => import('./login/login.module').then(x => x.LoginModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
